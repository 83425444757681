import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector, private messageService: MessageService) { }

  handleError(error: any) {
    let router = this.injector.get(Router);

    if (error instanceof HttpErrorResponse) {
     
    } else {
      console.log(error);
      this.messageService.add({ severity: 'error', summary: error.status, detail: "Internal Server error Please Contact Administrator" });
    }

  }
} 
