import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HpAppComponent } from './hp-app.component';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/components/common/messageservice';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FaqComponent } from './modules/shared/faq/faq.component';
import { DropdownModule } from 'primeng/dropdown';
import { LayoutComponent } from './modules/layout/layout/layout.component';
import { SignInComponent } from './modules/shared/sign-in/sign-in.component';
import {InputTextModule} from 'primeng/inputtext';
import { AuthGuardService } from './guards/auth-guard.service';
import { Role } from './models/role';
import {KeyFilterModule} from 'primeng/keyfilter';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { AdminserviceService } from './services/adminservice.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const routes: Routes =
  [
    {
      path: '',
      redirectTo: "/dashboard",
      pathMatch: "full"
    },

    {
      path: 'dashboard',
      component: LayoutComponent,
      children: [
        {
          path: '',
          loadChildren: './modules/user/user.module#UserModule',
        },
        {
          path: 'pod/:lan',
          loadChildren: './modules/user/user.module#UserModule',
        },
        {
          path: 'faq', component: FaqComponent
        },
        {
          path: 'order-management',
          loadChildren: './modules/admin/order-management/order-management.module#OrderManagementModule',
          canActivate: [AuthGuardService]
        },
        {
          path: 'configuration-management',
          loadChildren: './modules/admin/configuration-management/configuration-management.module#ConfigurationManagementModule',
          canActivate: [AuthGuardService]
        },
        {
          path: 'downloads-report',
          loadChildren: './modules/admin/downloads-report/downloads-report.module#DownloadsReportModule',
          canActivate: [AuthGuardService]
        },
        {
          path: 'user-management',
          loadChildren: './modules/admin/user-management/user-management.module#UserManagementModule',
          canActivate: [AuthGuardService]
        },
        {
          path: 'content-management',
          loadChildren: './modules/admin/content-management/content-management.module#ContentManagementModule',
          canActivate: [AuthGuardService]
        }
      ]
    },
 
    {
      path: 'signin',
      component : SignInComponent
    },
    {
      path: '**',
      redirectTo: "/dashboard"
    },
  ];

@NgModule({
  declarations: [
    HpAppComponent,
    FaqComponent,
    SignInComponent,
    LayoutComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes ,{ onSameUrlNavigation: 'reload' }),
    ToastModule,
    DropdownModule,
    InputTextModule,
    KeyFilterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })

  ],
  providers: [
    MessageService,
    AuthGuardService,
    GlobalErrorHandlerService,
    AdminserviceService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
  ],
  bootstrap: [HpAppComponent]
})
export class HpAppModule { }
