import { Component, OnInit, Inject } from '@angular/core';
import { Language } from 'src/hp-app/models/language';
import { UserserviceService } from 'src/hp-app/services/userservice.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Login } from 'src/hp-app/models/login';
import { Menu } from 'src/hp-app/models/menu';
import { AdminserviceService } from 'src/hp-app/services/adminservice.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { DOCUMENT, Location } from '@angular/common';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  languagelist : Array<Language> = new Array<Language>();
  menuList : Array<Menu> = new Array<Menu>();
  languageNames : Array<string> = new Array<string>();
  language :string;
  loggedinUser: Login;
  isuserloggedin = false;
  selectedlanguage: Language = new Language();
  role: string;
  direction: string = "ltr";

  constructor(private translate: TranslateService, private _http: UserserviceService, private location: Location, private route: ActivatedRoute
    , private router: Router, private _httpadmin: AdminserviceService, private messageService: MessageService, @Inject(DOCUMENT) private document: Document) {
  
 }

  ngOnInit(){

    this.getLanguageList();

    if(sessionStorage.getItem("user")!= null){
      this.loggedinUser = JSON.parse(sessionStorage.getItem("user"));
      this.isuserloggedin = true;
      this.role = this.loggedinUser.role;
      this.getMenuList(this.loggedinUser);
    }
  }
  
  /**
   *  @description Method to get Languages List 
   *  @author HariKiran 
   *  @date 10/10/2019 
   *  @memberof LayoutComponent 
   * */
  getLanguageList() {
  
    this._http.getLanguageList().subscribe(
      data =>  
      {
        this.languagelist = data
        this.languagelist.forEach(element => {
          this.languageNames.push(element.code)
        });
         this.translate.addLangs(this.languageNames);
        this.language = this._http.getGlobalLanguage();
        this.selectedlanguage = this.languagelist.find(s => s.code.replace(/\s/g, "") == this.language);
        this.translate.use(this.language);
        if (this.language.toLowerCase() == 'ar') {
        this.direction = "rtl";
        this.loadStyle('arabic.css');
       }
      }, err => {
        this.messageService.add({severity:'error', summary: 'Error Message', detail: err.message});
      }
    )
  }

  /**
   *  @description Method to Change Language 
   *  @author HariKiran 
   *  @date 10/10/2019 
   *  @memberof LayoutComponent 
   * */
  changeLanguage(lan :Language){
    this._http.updateGlobalLanguage(lan.code.replace(/\s/g, ""));
    window.location.reload();
  }

  /**
   *  @description Method to SignOut of the user
   *  @author HariKiran 
   * @date 10/10/2019 
   *  @memberof LayoutComponent 
   * */
  SignOut(){
    sessionStorage.removeItem("user");
    this.isuserloggedin = false;
    this.router.navigate(['/dashboard']);
  }

 /**
   *  @description Method to Get Menu List
   *  @author HariKiran 
   *  @date 10/10/2019 
   *  @memberof LayoutComponent 
   * */
  getMenuList(user: Login){
    this._httpadmin.getMenuList(user.roleId).subscribe(
      data =>  
      { 
          this.menuList = data
      }, err => {
        this.messageService.add({severity:'error', summary: 'Error Message', detail: err.message});
      }
    )
  }
  /**
  *  @description Method to navigate to modules based on dropdown select
  *  @author HariKiran
  *  @date 1/12/2019
  *  @memberof LayoutComponent
  * */
  DropdownMenu(name){
    if(name == 'Orders Management'){
      this.router.navigate(['/dashboard/order-management/list']);
    }else if(name == 'Configuration Management'){
      this.router.navigate(['/dashboard/configuration-management']);
    }else if(name == 'Downloads Report'){
      this.router.navigate(['/dashboard/downloads-report/list']);
    } else if(name == 'Users Management'){
      this.router.navigate(['/dashboard/user-management/list']);
    } else if(name == 'Content Management'){
      this.router.navigate(['/dashboard/content-management/list']);
    }

  }

  /**
  *  @description Method to navigate dashboard
  *  @author HariKiran
  *  @date 1/12/2019
  *  @memberof LayoutComponent
  * */
  dashboard(){
    this.router.navigate(['/dashboard']);
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];
    let themeLink = this.document.getElementById('client-theme') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;

    }
    else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;
      head.appendChild(style);
    }
  }

}
