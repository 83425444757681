import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators'; 
import { throwError } from 'rxjs';
import { DocumentFilter } from '../models/documentsfilter';

import { map } from 'rxjs/operators'; 
import { ProductDocument } from '../models/productdocument';
import { Address } from '../models/address';
import { OrderDetails } from '../models/orderdetails';
import { Language } from '../models/language';


@Injectable({
  providedIn: 'root'
})
export class UserserviceService {

  myAppUrl: string = "";


  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.myAppUrl = baseUrl;
  }  

  getCountryList(){
    return this._http.get<Array<any>>(this.myAppUrl +'api/User/CountryList')  
                     .pipe(catchError((res: Response) => this.errorHandler(res)))  
  }

  getProductTypeList(){
    return this._http.get<Array<any>>(this.myAppUrl +'api/User/ProductTypeList')  
                     .pipe(catchError((res: Response) => this.errorHandler(res)))  
  }

  getProductsubTypeList(Id){
    return this._http.get<Array<any>>(this.myAppUrl +'api/User/ProductSubTypeList/'+Id)  
                     .pipe(catchError((res: Response) => this.errorHandler(res)))  
  }

  getProductsList(Id){
    return this._http.get<Array<any>>(this.myAppUrl +'api/User/ProductList/'+Id)  
                     .pipe(catchError((res: Response) => this.errorHandler(res)))  
  }

  getProductDocuments(filter : DocumentFilter){
    return this._http.post<Array<any>>(this.myAppUrl +'api/User/ProductDocumentsList',filter)  
                     .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  downLoadDocument(doc,fileType) {
    return this._http.post(this.myAppUrl + "api/User/DownloadFile/", doc , { responseType:"blob" })
       .pipe(map(
         (res) => {
           var blob = new Blob([res], { type: fileType })
           return blob;
         }
       ));
  }

  sendEmail(filter: DocumentFilter) {
    return this._http.post(this.myAppUrl + 'api/User/EmailDocument', filter)
      .pipe(catchError((res: Response) => this.errorHandler(res)))
  }
  
  getDocumentbyId(doc:DocumentFilter){
    return this._http.post<ProductDocument>(this.myAppUrl + 'api/User/GetDocumentbyId/',doc)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  AddOrder(order: OrderDetails){
    return this._http.post(this.myAppUrl + 'api/User/AddOrder/',order)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getOrderDetails(orderId){
    return this._http.get(this.myAppUrl + 'api/User/GetOrderDetails/'+ orderId)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getOrderedProducts(orderId){
    return this._http.get<Array<ProductDocument>>(this.myAppUrl + 'api/User/GetUserOrderedproducts/'+ orderId)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getLanguageList(){
    return this._http.get<Array<Language>>(this.myAppUrl + 'api/User/GetLanguagelist')
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getGlobalLanguage(){
    let language = '';
    if (sessionStorage.getItem('globallanguage') != null) {
      language = JSON.parse(sessionStorage.getItem('globallanguage'));
    } else {
      sessionStorage.setItem('globallanguage', JSON.stringify('en'));
      language = JSON.parse(sessionStorage.getItem('globallanguage'));
    }
    return language;
  }

  updateGlobalLanguage(lan){
    sessionStorage.setItem('globallanguage', JSON.stringify(lan));
  }

  getProductsnosubType(productypeId){
    return this._http.get<Array<any>>(this.myAppUrl +'api/User/ProductListNoSubType/'+productypeId)  
    .pipe(catchError((res: Response) => this.errorHandler(res)))  
  }
  errorHandler(error: Response) {  
    return throwError(error);  
  }  

}
