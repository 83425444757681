import { Injectable }       from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AdminserviceService } from '../services/adminservice.service';
import { Login } from '../models/login';
import { Observable } from 'rxjs/internal/Observable';


@Injectable()
export class AuthGuardService implements CanActivate {
  
  User :Login = new Login();

  constructor(private authService: AdminserviceService, private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
 
    if(sessionStorage.getItem('user') != null){

      this.User = JSON.parse(sessionStorage.getItem('user'));

      let _data = {
        userId: this.User.userId,
        roleId: this.User.roleId,
        path: this.router.url
      }

      let isaccess = this.authService.getAccess(_data);

      if (Boolean(isaccess) == false) {
        this.router.navigate(["/signin"]);
        return false;
      }
      return true;
    }
    this.router.navigate(["/"]);
    
  }
} 
