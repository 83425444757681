import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Login } from '../models/login';
import { Menu } from '../models/menu';
import { OrderManagement } from '../models/ordermanagement';
import { ProductDocument } from '../models/productdocument';
import { ConfigurationManagementmenu } from '../models/configuration-management-menu';
import { Country } from '../models/country';
import { DocumentsType } from '../models/documents-type';
import { Language } from '../models/language';
import { Localizedlanguage } from '../models/localized-language';
import { DownloadsReport } from '../models/downloadsreport';
import { ProductType } from '../models/product-type';
import {  SubProductType } from '../models/subproduct-type';
import { Product } from '../models/product';
import { User } from '../models/user';
import { AccessLevel } from '../models/accesslevel';
import { BindType } from '../models/bind-type';
import { map } from 'rxjs/operators'
import { DocSpecsAudit } from '../models/docspecs-audit';
import { FileToUpload } from '../models/file-to-upload';
import { ContentManagementPrd } from '../models/contentmanagement-prd';
import { DocPrdsAudit } from '../models/DocPrdsAudit';

@Injectable({
  providedIn: 'root'
})
export class AdminserviceService {

  myAppUrl: string = "";


  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.myAppUrl = baseUrl;
  }  

  getAccess(data) {
    return this._http.post(this.myAppUrl + 'api/Admin/GetAccess/', data)
      .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  login(login:Login){
    return this._http.post<Login>(this.myAppUrl + 'api/Admin/Login/',login)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  forgotpassword(login:Login){
    return this._http.post<Login>(this.myAppUrl + 'api/Admin/VerifyEmail/',login)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  verifypassword(login:Login){
    return this._http.post<Login>(this.myAppUrl + 'api/Admin/VerifyCode/',login)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  resetPassword(login:Login){
    return this._http.post<Login>(this.myAppUrl + 'api/Admin/ResetPassword/',login)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getMenuList(roleId){
    return this._http.get<Array<Menu>>(this.myAppUrl + 'api/Admin/Menu/'+roleId)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getOrderManagementList(){
    return this._http.get<Array<OrderManagement>>(this.myAppUrl + 'api/Admin/GetOrderManagementList')
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getfilteredOrderManagementList(filter){
    return this._http.post<Array<OrderManagement>>(this.myAppUrl + 'api/Admin/GetFilteredOrderManagementList',filter)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getOrderedProductView(id,orderid){
    return this._http.get<ProductDocument>(this.myAppUrl + 'api/Admin/GetOrderedProductView/'+id+'/'+ orderid)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getConfigurationMenu(roleId){
    return this._http.get<Array<ConfigurationManagementmenu>>(this.myAppUrl + 'api/Admin/GetConfigurationManagementMenuList/'+roleId)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getStatusList(){
    return this._http.get<Array<any>>(this.myAppUrl + 'api/Admin/GetStatusList')
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getCountryAdminstrationList(){
    return this._http.get<Array<Country>>(this.myAppUrl + 'api/Admin/CountryManagementList')
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  addCountry(country){
    return this._http.post(this.myAppUrl + 'api/Admin/AddCountry',country)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  updateCountry(country){
    return this._http.post(this.myAppUrl + 'api/Admin/UpdateCountry',country)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getCountrybyId(Id){
    return this._http.get<Country>(this.myAppUrl + 'api/Admin/GetCountryDetails/'+Id)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }
  
  getDcoumentTypeAdminstrationList(){
    return this._http.get<Array<DocumentsType>>(this.myAppUrl + 'api/Admin/GetDocumentTypeList')
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getDocumenttypebyId(Id){
    return this._http.get<DocumentsType>(this.myAppUrl + 'api/Admin/GetDocumentTypDetails/'+Id)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  addDocumentType(doctype){
    return this._http.post(this.myAppUrl + 'api/Admin/AddDocumentType',doctype)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  updateDocumentType(doctype){
    return this._http.post(this.myAppUrl + 'api/Admin/UpdateDocumentType',doctype)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }


  getLanguageAdminstrationList(){
    return this._http.get<Array<Language>>(this.myAppUrl + 'api/Admin/GetLanguageAdminList')
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getLanguagebyId(Id){
    return this._http.get<Language>(this.myAppUrl + 'api/Admin/GetLanguageDetails/'+Id)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  addLanguage(lang){
    return this._http.post(this.myAppUrl + 'api/Admin/AddLanguage',lang)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  updateLanguage(lang){
    return this._http.post(this.myAppUrl + 'api/Admin/UpdateLanguage',lang)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getLocalizedLanguageAdminstrationList(){
    return this._http.get<Array<Localizedlanguage>>(this.myAppUrl + 'api/Admin/GetLocalizedLanguages')
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getLocalizedLanguagebyId(Id){
    return this._http.get<Localizedlanguage>(this.myAppUrl + 'api/Admin/GetLocalizedLanguageDetails/'+Id)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  addLocalizedLanguage(lang){
    return this._http.post(this.myAppUrl + 'api/Admin/AddLocalizedLanguage',lang)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  updateLocalizedLanguage(lang){
    return this._http.post(this.myAppUrl + 'api/Admin/UpdateLocalizedLanguage',lang)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }


  getDownLoadsReportList(){
    return this._http.get<Array<DownloadsReport>>(this.myAppUrl + 'api/Admin/GetDownLoadsReport')
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getfilteredDownLoadsReportList(filter){
    return this._http.post<Array<DownloadsReport>>(this.myAppUrl + 'api/Admin/GetFilteredDownLoadsReport',filter)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getDownLoadReportView(Id){
    return this._http.get<DownloadsReport>(this.myAppUrl + 'api/Admin/GetDownLoadReportView/'+Id)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getAdminProductTypeList(){
    return this._http.get<Array<ProductType>>(this.myAppUrl + 'api/Admin/GetAdminProductTypeList')
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getproducttypebyId(Id){
    return this._http.get<ProductType>(this.myAppUrl + 'api/Admin/GetProductTypeDetails/'+Id)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  updateProducttype(producttype){
    return this._http.post(this.myAppUrl + 'api/Admin/UpdateProductType',producttype)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getFilterProductTypeList(filter){
    return this._http.get<Array<ProductType>>(this.myAppUrl + 'api/Admin/GetFilterProductTypeList/'+ filter)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  addProducttype(producttype){
    return this._http.post(this.myAppUrl + 'api/Admin/AddProductType',producttype)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getSubProductTypeListById(Id){
    return this._http.get<Array<SubProductType>>(this.myAppUrl + 'api/Admin/GetSubProductTypeListById/'+Id)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getSubProductTypeDetailsById(productypeId,productsubtypeId){
    return this._http.get<SubProductType>(this.myAppUrl + 'api/Admin/GetSubProductTypeDetailsById/'+productypeId+'/'+ productsubtypeId)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  updateSubProducttype(subproducttype){
    return this._http.post(this.myAppUrl + 'api/Admin/UpdateSubProductType',subproducttype)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }
 
  getFilterSubProductTypeList(Id,filter){
    return this._http.get<Array<SubProductType>>(this.myAppUrl + 'api/Admin/GetFilterSubProductTypeList/'+Id+'/'+filter)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }
  addSubProducttype(subproducttype){
    return this._http.post(this.myAppUrl + 'api/Admin/AddSubProductType',subproducttype)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getProductListbyPrdsubtypeId(subproducttype){
    return this._http.post<Array<Product>>(this.myAppUrl + 'api/Admin/GetProductsList',subproducttype)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getProductDetails(product){
    return this._http.post<Product>(this.myAppUrl + 'api/Admin/GetProductDetails',product)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  updateProduct(product){
    return this._http.post(this.myAppUrl + 'api/Admin/UpdateProduct',product)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  addProduct(product){
    return this._http.post(this.myAppUrl + 'api/Admin/AddProduct',product)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getFilterProductList(producttypeId,subproducttypeId,filter){
    return this._http.get<Array<Product>>(this.myAppUrl + 'api/Admin/GetFilterProductList/'+producttypeId+'/'+subproducttypeId+'/'+ filter)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getUserList(){
    return this._http.get<Array<any>>(this.myAppUrl + 'api/Admin/GetUserList')
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getUserDetailsbyId(userId){
    return this._http.get<Array<any>>(this.myAppUrl + 'api/Admin/GetUserDetails/'+userId)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }
  
  getRoleList(){
    return this._http.get<Array<AccessLevel>>(this.myAppUrl + 'api/Admin/GetAccessLevelList')
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  addUser(user){
    return this._http.post(this.myAppUrl + 'api/Admin/AddUser',user)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  updateUser(user){
    return this._http.post(this.myAppUrl + 'api/Admin/UpdateUser',user)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getExcelReport(){
    return this._http.get<Array<any>>(this.myAppUrl + 'api/Admin/GetProductsExcelReport')
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }


  getBindingTypeList(){
    return this._http.get<Array<BindType>>(this.myAppUrl + 'api/Admin/GetBindTypesList')
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getLocalizedLanguageCountries(languageId){
    return this._http.get<Array<Country>>(this.myAppUrl + 'api/Admin/GetLanguageCountryList/'+languageId)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  uploadFile(file){
    return this._http.post(this.myAppUrl + 'api/Admin/UploadDocument',file)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  uploadUpdateDocument(file,docId){
    file["documentId"]= +docId;
     return this._http.post(this.myAppUrl + 'api/Admin/UploadUpdateDocument',file)
     .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  AddDocSpecs(docspecs,docproductsList){
    let obj = {
      specs:docspecs,
      docPrds:docproductsList
    }
    return this._http.post(this.myAppUrl + 'api/Admin/AddDocSpecs',obj)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }


  updateDocSpecs(docspecs,docproductsList){
    let obj = {
      specs:docspecs,
      docPrds:docproductsList
    }
    return this._http.post(this.myAppUrl + 'api/Admin/UpdateDocSpecs',obj)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getdocumentspecsList(){
    return this._http.get<Array<any>>(this.myAppUrl + 'api/Admin/GetDocumentsSpecificationsList')
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getDocumentSpecsbyDocumentId(docId){
    return this._http.get<any>(this.myAppUrl + 'api/Admin/GetDocSpecsbyDocId/'+docId)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }       

  documentfileDownLoad(path,fileType) {
   let file = new FileToUpload();
   file.filePath = path;
   return this._http.post(this.myAppUrl + "api/Admin/DownloadDocumentbyPath",file, { responseType:"blob" })
      .pipe(map(
        (res) => {
          var blob = new Blob([res], { type: fileType })
          return blob;
        }
      ));
  }

  deleteDocument(path){
    let file = new FileToUpload();
    file.filePath = path;
    return this._http.post(this.myAppUrl + "api/Admin/DeleteDocumentbyPath",file)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  deleteCancelDocumentbyPath(path,docId){
    let file = new FileToUpload();
    file.filePath = path;
    file.documentId = docId;
    return this._http.post(this.myAppUrl + "api/Admin/DeleteCancelDocumentbyPath",file)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getDocumentSpecsAuditlistbyDocumentId(docId){
    return this._http.get<Array<DocSpecsAudit>>(this.myAppUrl + 'api/Admin/GetDocumentSpecsAuditList/'+docId)
    .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getDocumentPrdsAuditlistbyDocumentId(docId) {
    return this._http.get<Array<DocPrdsAudit>>(this.myAppUrl + 'api/Admin/GetDocumentPrdsAuditList/' + docId)
      .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  addproductsexcelList(list,createdby) {

 let data ={
   list: list,
   createdby: createdby
 }
    return this._http.post(this.myAppUrl + "api/Admin/AddProductsExcelReport",data)
      .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getExcelHeaders() {
    return this._http.get<Array<any>>(this.myAppUrl + 'api/Admin/GetExcelHeaders')
      .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getContentManagementProductSubtypeList(producttypeIds) {

    let headers = {headers: new HttpHeaders({ 'Content-Type': 'application/json'})}

    return this._http.post<any>(this.myAppUrl + 'api/Admin/GetContentManagementProductSubtypeList', JSON.stringify(producttypeIds), headers)
      .pipe(catchError((res: Response) => this.errorHandler(res)))
  }
  getContentManagementPrdtypeNoSubtypeList(producttypeIds) {

    let headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }

    return this._http.post<any>(this.myAppUrl + 'api/Admin/GetContentManagementPrdtypeNoSubtypeList', JSON.stringify(producttypeIds), headers)
      .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getContentManagementProductsList(selectedsubprodtypelist) {
    return this._http.post<Array<ContentManagementPrd>>(this.myAppUrl + 'api/Admin/GetContentManagementProductsList', selectedsubprodtypelist)
      .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  getAdminSubProductypeList() {
    return this._http.get<Array<any>>(this.myAppUrl + 'api/Admin/GetAdminSubProductypeList')
      .pipe(catchError((res: Response) => this.errorHandler(res)))
  }

  addselecteddocsproducts(docIds,products,userId) {
    let data = {
      userId:userId,
      products: products,
      docIds: docIds
    }
    return this._http.post<any>(this.myAppUrl + 'api/Admin/AddSelectedDocsProducts', data)
      .pipe(catchError((res: Response) => this.errorHandler(res)))
  }
  errorHandler(error: Response) {  
    return throwError(error);  
  }  

}
