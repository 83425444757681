import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserserviceService } from './services/userservice.service';
import { Language } from './models/language';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './hp-app.component.html',
  styleUrls: ['./hp-app.component.css']
})
export class HpAppComponent  {
  title = 'app';

  constructor() {
  }

}
