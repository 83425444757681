import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserserviceService } from '../../../services/userservice.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  language : string;

  constructor(private translate: TranslateService, private _http: UserserviceService) {
    this.language = this._http.getGlobalLanguage();
    translate.use(this.language);
  }

  ngOnInit() {
  }


  getCSSClasses() {
    let cssClasses;

    if (this.language.toLowerCase() == "ar") {
      cssClasses = {
       'text-right':true
      }
    }
    else {
      cssClasses = {
        'text-right': false
      }
    }
    return cssClasses;
  }
}
