import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminserviceService } from 'src/hp-app/services/adminservice.service';
import { Login } from 'src/hp-app/models/login';
import { MessageService } from 'primeng/components/common/messageservice';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  signinForm: FormGroup;
  forgotpasswordForm:FormGroup;
  passcodeForm :  FormGroup;
  resetpasswordForm : FormGroup;
  header:string = 'Login';
  
  submitted = false;
  fpsubmitted = false;
  passcodesubmitted = false;
  resetpassssubmitted = false

  isuserForgotPassword = false;
  isemailVerified = false;
  isloginForm = true;
  isresetpassword = false;
  iserror = false;
  isClassOneActive = false;
  errormessage : string;

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  blockSpace: RegExp = /[^\s]/; 
  login:Login;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _http: AdminserviceService,
    private messageService : MessageService
  ) {

  }

  ngOnInit() {
    this.signinForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required,Validators.pattern(this.emailPattern)]],
      password: ['', Validators.required]
    });
    this.forgotpasswordForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required,Validators.pattern(this.emailPattern)]],
    });
    this.passcodeForm = this.formBuilder.group({
      emailAddress: [''],
      password: ['', Validators.required]
    });
    this.resetpasswordForm = this.formBuilder.group({
      emailAddress: [''],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    },{
      validator: this.MustMatch('password', 'confirmPassword')
    });
  }

  /**
   *  @description Method to Login the User
   *  @author HariKiran 
   * @date 10/15/2019 
   *  @memberof SignInComponent 
   * */
  onSubmit() {
    this.submitted = true;
    if (this.signinForm.invalid) {
      return;
    }
    this.login = this.signinForm.value;
    this._http.login(this.login).subscribe(
      data => {
        this.submitted = false;
        this.iserror = false;
        sessionStorage.setItem("user", JSON.stringify(data));
        this.signinForm.reset();
        this.router.navigate(['/dashboard']);
      },
      err  => {
        if(err["error"] != null){
          this.iserror = true;
          this.errormessage = err["error"];
          this.isClassOneActive = true;
        }else{
      
            this.messageService.add({severity:'error', summary: 'Error Message', detail: err.message});
          
        }
      }
    )
  }

  /**
   *  @description Method to send passcode to the registerd mail
   *  @author HariKiran 
   * @date 10/15/2019 
   *  @memberof SignInComponent 
   * */
  onForgotpassword(){

    this.fpsubmitted = true;
    this.login = new Login();

    if (this.forgotpasswordForm.invalid) {
      return;
    }
    this.login = this.forgotpasswordForm.value;
    this._http.forgotpassword(this.login).subscribe(
      data => {

        this.isemailVerified = true;
        this.isuserForgotPassword = false;
        this.isloginForm = false;
        this.isresetpassword = false;
        this.iserror = false;

        this.forgotpasswordForm.reset();

        this.messageService.add({severity:'success', summary: 'Passcode sent to mail', detail:'Successfully'});

        this.passcodeForm.controls.emailAddress.setValue(data["emailAddress"]) ;
      },
      err  => {
        if(err["error"] != null){
          this.iserror = true;
          this.errormessage = err["error"];
          this.isClassOneActive = false;
        }else{
          this.messageService.add({severity:'error', summary: 'Error Message', detail: err.message});
        }
      }
    )
  }
  
  /**
   *  @description Method to Check the passcode send to the mail
   *  @author HariKiran 
   * @date 10/15/2019 
   *  @memberof SignInComponent 
   * */
  onPasscodeSubmit(){
    this.passcodesubmitted = true;
    this.login = new Login();
    if (this.passcodeForm.invalid) {
      return;
    }
    this.login = this.passcodeForm.value;

    this._http.verifypassword(this.login).subscribe(
      data => {
        this.isemailVerified = false;
        this.isuserForgotPassword = false;
        this.isloginForm = false;
        this.isresetpassword = true;
        this.passcodeForm.reset();
        this.header = 'Reset Password';
        this.iserror = false;
        this.resetpasswordForm.controls.emailAddress.setValue(data["emailAddress"]) ;
      },
      err => {
        console.log(err["error"]);
        if(err["error"] != null){
          this.iserror = true;
          this.errormessage = err["error"];
          this.isClassOneActive = false;
        }else{
          this.messageService.add({severity:'error', summary: 'Error Message', detail: err.message});
        }
      }
    )
  }

  /**
   *  @description Method to reset password
   *  @author HariKiran 
   * @date 10/15/2019 
   *  @memberof SignInComponent 
   * */
  onNewpasswordSubmit(){

    this.resetpassssubmitted = true;

    this.login = new Login();

    if (this.resetpasswordForm.invalid) {
      return;
    }
    this.login = this.resetpasswordForm.value;

    this._http.resetPassword(this.login).subscribe(
      data => {

        this.isemailVerified = false;
        this.isuserForgotPassword = false;
        this.isloginForm = true;
        this.isresetpassword = false;
        this.iserror = false;

        this.fpsubmitted = false;
        this.passcodesubmitted = false;
        this.resetpassssubmitted = false;
        this.submitted = false; 

        this.resetpasswordForm.reset();
        this.signinForm.reset();
        this.forgotpasswordForm.reset();
        this.passcodeForm.reset();

        this.messageService.add({ severity: 'success', summary: 'Password Updated', detail: 'Successfully' });
        this.header = 'Login';
      },
      err  => {
        if(err["error"] != null){
          this.iserror = true;
          this.errormessage = err["error"];
        }else{
          this.messageService.add({severity:'error', summary: 'Error Message', detail: err.message});
        }
      }
    )
  }

  /**
   *  @description Method to calls on forgot password 
   *  @author HariKiran 
   * @date 10/15/2019 
   *  @memberof SignInComponent 
   * */
  forgotpassword(){

    this.isuserForgotPassword = true;
    this.isloginForm = false;
    this.isemailVerified = false;
    this.isresetpassword = false;

    this.fpsubmitted = false;
    this.passcodesubmitted = false;
    this.resetpassssubmitted = false;
    this.iserror = false;

    this.signinForm.reset();
    this.forgotpasswordForm.reset();
    this.passcodeForm.reset();
    this.resetpasswordForm.reset();

    this.header = 'Password Recovery';
  }

  /**
   *  @description Method to return to the Login page
   *  @author HariKiran 
   * @date 10/15/2019 
   *  @memberof SignInComponent 
   * */
  cancel(){

    this.isuserForgotPassword = false;
    this.isloginForm = true;
    this. isemailVerified = false;
    this.isresetpassword = false;

    this.header = 'Login';

    this.signinForm.reset();
    this.forgotpasswordForm.reset();
    this.passcodeForm.reset();
    this.resetpasswordForm.reset();
  
    this.fpsubmitted = false;
    this.passcodesubmitted = false;
    this.resetpassssubmitted = false;
    this.submitted = false; 

    this.iserror = false;
  }

  /**
   *  @description Method to go back to the Dashboard
   *  @author HariKiran 
   * @date 10/15/2019 
   *  @memberof SignInComponent 
   * */
  back(){
    
    this.signinForm.reset();
    this.forgotpasswordForm.reset();
    this.passcodeForm.reset();
    this.resetpasswordForm.reset();

    this.fpsubmitted = false;
    this.passcodesubmitted = false;
    this.resetpassssubmitted = false;
    this.iserror = false;

    this.router.navigate(["/dashboard"]);
  }

  /**
   *  @description Method to check newpassword and Confirm password are equal
   *  @author HariKiran 
   * @date 10/15/2019 
   *  @memberof SignInComponent 
   * */
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }


  get f() { return this.signinForm.controls; }

  get g() { return this.forgotpasswordForm.controls; }

  get h() { return this.passcodeForm.controls; }

  get e() { return this.resetpasswordForm.controls; }
}
